<template>
  <div>
    <a-modal
      v-model="visible"
      width="768px"
      :confirmLoading="loading"
      :destroyOnClose="true"
      :maskClosable="false"
      centered
      @cancel="cancel"
      @ok="confirm"
    >
      <div slot="title">{{ form.id ? `编辑产品 - ${form.name}` : "新增产品" }}</div>
      <div>
        <a-form-model
          ref="form"
          :model="form"
          :rules="rules"
          :label-col="{ span: 4, md: 6 }"
          :wrapper-col="{ span: 16, md: 18 }"
        >
          <a-row gutter="16">
            <a-col :span="24" :md="12">
              <a-form-model-item prop="number" label="产品编号">
                <a-input v-model="form.number" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="name" label="产品名称">
                <a-input v-model="form.name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="english_name" label="英文名称">
                <a-input v-model="form.english_name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="barcode" label="产品条码">
                <a-input v-model="form.barcode" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="category" label="分类">
                <CategorySelect v-model="form.category" :name="form.category_name"></CategorySelect>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="production_area" label="产区">
                <a-input v-model="form.production_area" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="classification" label="品类">
                <a-input v-model="form.classification" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="country" label="国家">
                <a-input v-model="form.country" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="supplier" label="供应商">
                <a-input v-model="form.supplier" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="unit_price" label="单价">
                <a-input-number v-model="form.unit_price" style="width: 100%" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="unit" label="单位">
                <a-select v-model="form.unit" allowClear style="width: 100%">
                  <a-select-option v-for="item in $parent.unitItems" :key="item.id" :value="item.name">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="length" label="长度(厘米)">
                <a-input-number v-model="form.length" style="width: 100%" @change="calculateVolume" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="width" label="宽度(厘米)">
                <a-input-number v-model="form.width" style="width: 100%" @change="calculateVolume" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="height" label="高度(厘米)">
                <a-input-number v-model="form.height" style="width: 100%" @change="calculateVolume" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="volume" label="体积(立方米)">
                <a-input-number v-model="form.volume" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="weight" label="毛重(千克)">
                <a-input-number v-model="form.weight" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="net_weight" label="净重(千克)">
                <a-input-number v-model="form.net_weight" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="spec" label="包装规格">
                <a-input v-model="form.spec" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="stock_out_strategy" label="出库策略">
                <a-select v-model="form.stock_out_strategy" style="width: 100%">
                  <a-select-option value="fifo">先进先出(FIFO)</a-select-option>
                  <a-select-option value="fefo">先到期先出(FEFO)</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="enable_batch_control" label="批次控制">
                <a-select v-model="form.enable_batch_control" style="width: 100%">
                  <a-select-option :value="true">开启</a-select-option>
                  <a-select-option :value="false">关闭</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="enable_shelf_life" label="启用保质期">
                <a-select v-model="form.enable_shelf_life" style="width: 100%">
                  <a-select-option :value="true">开启</a-select-option>
                  <a-select-option :value="false">关闭</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="shelf_life_days" label="保质期(天)">
                <a-input-number v-model="form.shelf_life_days" :min="0" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="shelf_life_warning_days" label="预警天数">
                <a-input-number v-model="form.shelf_life_warning_days" :min="0" style="width: 100%" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="is_active" label="状态">
                <a-select v-model="form.is_active" style="width: 100%">
                  <a-select-option :value="true">激活</a-select-option>
                  <a-select-option :value="false">冻结</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="remark" label="备注">
                <a-input v-model="form.remark" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="remark" label="图片">
                <MaterialImageUpload v-model="form.images" :initialItems="form.image_items" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { materialCreate, materialUpdate } from "@/api/data";
import NP from "number-precision";
import rules from "./rules.js";

export default {
  components: {
    CategorySelect: () => import("@/components/CategorySelect/"),
    MaterialImageUpload: () => import("@/components/MaterialImageUpload/"),
  },
  props: ["visible", "form"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules,
      loading: false,
    };
  },
  methods: {
    confirm() {
      if (
        this.form.inventory_upper != undefined &&
        this.form.inventory_lower != undefined &&
        this.form.inventory_upper < this.form.inventory_lower
      ) {
        this.$message.error("库存下限不能大于库存上限");
        return;
      }

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.form.id ? materialUpdate : materialCreate;
          func(this.form)
            .then((data) => {
              this.$message.success(this.form.id ? "修改成功" : "新增成功");
              this.$emit(this.form.id ? "update" : "create", data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
    calculateVolume() {
      const length = this.form.length || 0;
      const width = this.form.width || 0;
      const height = this.form.height || 0;
      this.form.volume = NP.times(length, width, height, 0.000001);
    },
  },
};
</script>

<style scoped></style>
