export default {
  number: [
    { required: true, message: "请输入产品编号", trigger: "change" },
    { max: 32, message: "超出最大长度 (32)", trigger: "change" },
  ],
  name: [
    { required: true, message: "请输入产品名称", trigger: "change" },
    { max: 256, message: "超出最大长度 (256)", trigger: "change" },
  ],
  barcode: [{ required: true, message: "请输入产品条码", trigger: "change" }],
  category: [{ required: true, message: "请选择产品分类", trigger: "change" }],
  unit: [{ required: true, message: "请选择产品单位", trigger: "change" }],
  remark: [{ max: 256, message: "超出最大长度 (256)", trigger: "change" }],
};
